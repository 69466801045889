// React Required
import React, { Component, useEffect } from 'react';
import ReactDOM from "react-dom";

// Create Import File
import './index.scss';
import './assets/scss/customStyles.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Business from './home/Business';
import CorporateBusiness from './home/CorporateBusiness';
import CreativeAgency from './home/CreativeAgency';
import CreativeLanding from './home/CreativeLanding';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import Rodilleras from './home/Rodilleras';
import DigitalAgency from './home/DigitalAgency';
import Home from './home/Home';
import HomeParticles from './home/HomeParticles';
import InteriorLanding from './home/Interior';
import MainDemo from './home/MainDemo';
import Paralax from './home/Paralax';
import PersonalPortfolio from './home/PersonalPortfolio';
import PortfolioLanding from './home/PortfolioLanding';
import Startup from './home/Startup';
import StudioAgency from './home/StudioAgency';

// Dark Home Layout 
import HomePortfolio from './dark/HomePortfolio';
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import About from "./elements/About";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import error404 from "./elements/error404";


// Blocks Layout
import Brand from "./blocks/Brand";
import Button from "./blocks/Button";
import Columns from "./blocks/Columns";
import ContactForm from "./blocks/ContactForm";
import Counters from "./blocks/Counters";
import Gallery from "./blocks/Gallery";
import GoogleMap from "./blocks/GoogleMap";
import Portfolio from "./blocks/Portfolio";
import PricingTable from "./blocks/PricingTable";
import ProgressBar from "./blocks/ProgressBar";
import Team from "./blocks/Team";
import Testimonial from "./blocks/Testimonial";
import VideoPopup from "./blocks/VideoPopup";

import Login from './home/Login';

import ReactGA from "react-ga4"


import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import CookieConsent, { Cookies } from "react-cookie-consent";

import CookiesPolicy from './home/CookiesPolicy';
import Register from './home/Register';





// Función para enviar el evento de página vista
const sendPageView = (location) => {
    ReactGA.initialize('G-ZWL4KM7PEM');
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
};

function Root() {

    useEffect(() => {
        // Inicializar Google Analytics con tu ID de seguimiento
        const consent = Cookies.get('manageCookieConsent');
        if (consent === "accepted") {

            sendPageView(window.location);
        }

    }, []);


    const handleAccept = () => {
        console.log("Cookies aceptadas");
        Cookies.set('userConsent', 'accepted', { expires: 365 });
        sendPageView(window.location); // Inicializa Google Analytics si las cookies son aceptadas
    };

    const handleDecline = () => {
        console.log("Cookies rechazadas");
        Cookies.set('userConsent', 'declined', { expires: 365 });
        // Eliminar cookies específicas de Google Analytics
        Cookies.remove('_ga');
        Cookies.remove('_gid');
        Cookies.remove('_gat');
        Cookies.remove('_ga_ZWL4KM7PEM');
    };


    return (
        <BrowserRouter basename={'/'}>
            <PageScrollTop>
                <Switch>
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo} /> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                    <Route exact path={`${process.env.PUBLIC_URL}/ejercicios`} component={DesignerPortfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/g/5fd1f79d-750e-4d10-a0c6-e1ab7862a606`} component={Rodilleras} />

                    {/* <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} /> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} /> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo} />
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo} />
                    <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup} />
                    <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax} />

                    <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency} />
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency} />
                    <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency} />
                    <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business} />
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding} />
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding} />
                    <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles} />
                    <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding} />
                    <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding} />
                    <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness} /> */}


                    {/* Element Layot */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service} />
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails} /> */}
                    <Route exact path={`${process.env.PUBLIC_URL}/contacto`} component={Contact} />
                    <Route exact path={`${process.env.PUBLIC_URL}/politica-de-cookies`} component={CookiesPolicy} />
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                    <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} /> */}


                    {/* Blocks Elements  */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL}/button`} component={Button} />
                    <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} />
                    <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters} />
                    <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial} />
                    <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />
                    <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup} />
                    <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery} />
                    <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand} />
                    <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar} />
                    <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm} />
                    <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap} />
                    <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns} />
                    <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable} /> */}




                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                    <Redirect to="/" />

                </Switch>
            </PageScrollTop>
            <CookieConsent
                location="bottom"
                buttonText="Aceptar"
                declineButtonText="Rechazar"
                enableDeclineButton
                overlay
                onAccept={handleAccept}
                onDecline={handleDecline}
                cookieName="manageCookieConsent"
                style={{ background: "var(--color-primary)", borderTop: "2px solid var(--color-primary)", fontSize: "16px", color: "white", fontWeight: "600", fontFamily: "Poppins, sans-serif", borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
                buttonStyle={{ background: "black", fontSize: "14px", color: "white", fontWeight: "bold", fontFamily: "Poppins, sans-serif", borderRadius: "12px", paddingBlock: "8px", paddingInline: "16px" }}
                declineButtonStyle={{ background: "black", fontSize: "14px", color: "white", fontWeight: "bold", fontFamily: "Poppins, sans-serif", borderRadius: "12px", paddingBlock: "8px", paddingInline: "16px" }}

                buttonClasses="accept-cookie-btn"
                declineButtonClasses="decline-cookie-btn"
            >
                Este sitio web utiliza cookies para mejorar la experiencia del usuario.{" "} Puede consultar nuestra
                <a href="/politica-de-cookies" style={{ color: "#f5d4d4", textDecoration: "underline", marginLeft: "5px" }}>Política de cookies</a>
            </CookieConsent>
        </BrowserRouter>
    )

}



ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();