import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";
import ParticlesComponent from "../component/common/Particles";
import caraIcon from "../assets/images/icons/cara-icon.svg"
import logoFinyt from "../assets/images/logo/logo-finyt-blanco.svg";
import logoFinytRecortado from "../assets/images/logo/logo-finyt-recortado.svg";
import iconoEstrella from "../assets/images/icons/icono-estrella.svg"

import { videoTagString, VideoTag } from "react-video-tag";

import designerAvatar from "../assets/images/about/designer-avatar.png";
import Particles from "react-tsparticles";
import EjRodilleras from "../elements/tab/EjRodilleras";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

class Rodilleras extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div>
        <Helmet pageTitle="FINYT - Guía de ejercicios" description="Guía de ejercicios para realizar con tu pack 7 en 1." noindex nofollow >
          <title>FINYT - Guía de ejercicios</title>

        </Helmet>

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-white"

        />
        {/* End Header Area  */}

        <div className="btn-hire">
          <a style={{ backgroundColor: "#ff2e43", fontSize: "12px", color: "white" }} className="btn-default" href="/contacto">
            ¿Alguna duda?
          </a>
        </div>


        <div style={{ height: "10px", width: "100%", backgroundColor: "black", paddingBottom: "100px" }}>
          <div style={{
            height: "2px", width: "80vw", margin: "auto",
            // backgroundColor: "#757575",
            backgroundColor: "black"
          }}>

          </div>
        </div>
        <div style={{ height: "52vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "80px" }}>
          <h1 className="titulos-principales" style={{ color: "white", textAlign: "center", margin: "0px", fontSize: "72px" }}>Bienvenid@ a <img className="titulos-principales-logo" src={logoFinytRecortado} alt="" /></h1>

          <TextLoop className="ejercicios-text-loop">
            {/* <p className="ejercicios-text-loop-p" style={{ textAlign: "center", color: "var(--color-primary)", backgroundColor: "black", margin: "0px", fontSize: "44px", fontWeight: "600" }}>Se acabaron las excusas.</p> */}
            <p className="ejercicios-text-loop" style={{ textAlign: "center", color: "var(--color-primary)", margin: "0px", fontSize: "44px", fontWeight: "700" }}>Esta vez sí.</p>
          </TextLoop>

        </div>
        <div style={{ displayflex: "flex", justifyContent: "center", alignItems: "center", height: "1px", width: "100vw", backgroundColor: "black", paddingBlock: "80px" }}>
          <div style={{ display: "flex", height: "2.5px", width: "92vw", margin: "auto", backgroundColor: "white" }}></div>
        </div>


        <div style={{ background: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100vw" }}>
          <h2 className="titulo-guia-ejercicios" style={{ textAlign: "center", fontWeight: "600", color: "white", backgroundColor: "black", margin: "0px", fontSize: "56px", paddingBottom: "32px" }}>Guía de ejercicios</h2>

          <div className="descripcion-guia-ejercicios-wrapper" style={{ textAlign: "center", color: "white", backgroundColor: "black", margin: "0px", fontSize: "18px" }}>
            <p className="descripcion-guia-ejercicios subtitulo">¡Muchas gracias por confiar en <strong>FINYT</strong>!</p>

            <p className="descripcion-guia-ejercicios texto-descriptivo"> A continuación te dejamos una guía de ejercicios en vídeo para que puedas aprovechar al máximo tu set 💪</p></div>


        </div>

        {/* Comentado esto mientras se construye el diseño */}
        {/* Start Designer Portfolio  */}
        <div className="designer-portfolio-area pt--60 pb--80  bg_color--6">
          <div className="wrapper plr--70 plr_sm--30 plr_md--30">
            <EjRodilleras column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>

        </div>
        {/* <div style={{ displayflex: "flex", justifyContent: "center", alignItems: "center", height: "1px", width: "100vw", backgroundColor: "black", paddingBlock: "80px" }}>
          <div style={{ display: "flex", height: "2.5px", width: "92vw", margin: "auto", backgroundColor: "white" }}></div>
        </div> */}
        <div className="texto-reviews-container">

          <div className="texto-reviews-wrapper">

            <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0, zIndex: -1 }}>
              <Particles
                id="tsparticles"
                options={{
                  background: {
                    color: {
                      value: "#000", // Color del fondo
                    },
                  },
                  fpsLimit: 60,
                  particles: {
                    color: {
                      value: "#fff", // Color de las partículas
                      opacity: 0.2,
                    },
                    links: {
                      color: "#fff",
                      distance: 150,
                      enable: false,
                      opacity: 0.4,
                      width: 1,
                    },
                    collisions: {
                      enable: true,
                    },
                    move: {
                      direction: "bottom",
                      enable: true,
                      outMode: "out",
                      random: true,
                      speed: 1,
                      straight: false,
                      bounce: false,


                    },
                    number: {
                      density: {
                        enable: true,
                        area: 800,
                      },
                      value: 45,
                    },
                    opacity: {
                      value: 0.3,
                    },
                    shape: {
                      type: "image",
                      image: {
                        src: logoFinyt,
                        width: 400,
                        height: 400

                      },

                    },
                    size: {
                      random: true,
                      value: 26,
                    },
                    // rotate: {
                    //   value: {
                    //     min: 0,
                    //     max: 360, // Define un rango de rotación para las partículas
                    //   },
                    //   animation: {
                    //     enable: true, // Habilitamos la animación de rotación
                    //     speed: 10,    // Velocidad de rotación
                    //     sync: false,  // Si `sync` es `true`, todas las partículas rotan al mismo tiempo
                    //   },
                    //   direction: "random", // La dirección de la rotación será aleatoria
                    //   random: true, // Habilita la rotación aleatoria
                    // },
                  },
                  detectRetina: true,
                }
                }


              />
            </div>
            {/* //* POR CAMBIAR: Cambiar link a valoracion rodilleras */}
            <h1 className="texto-reviews-titulo">Tu experiencia cuenta</h1>
            <p className="texto-reviews-descripcion">
              Comparte tu valoración y ayuda a otros compradores
            </p>
            <a
              className="btn-default btn-buy texto-reviews-button"
              target="_blank"
              href="https://www.amazon.es/gp/legacy/order-history?opt=ab&search=finyt"
            >
              <img className="texto-reviews-button-icon" src={iconoEstrella} alt="" />
              <span>Valorar</span>
              <img className="texto-reviews-button-icon" src={iconoEstrella} alt="" />
            </a>


          </div>


        </div>
        {/* End Designer Portfolio  */}
        {/* //* ACABAR DE CREAR ESTO */}
        {/* <div className="otros-productos-wrapper">
          <h1 className="otros-product">Otros productos</h1>
        </div> */}
        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div >
    );
  }
}

export default Rodilleras;
